import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from '@/components/Modal/Modal';
import { Button } from '@/components/Button/Button';
import * as S from './ErrorModal.styles';
import { ErrorModalConfig } from '@/contexts/ErrorModalContext/ErrorModalContext';

type ErrorModalProps = ErrorModalConfig & {
  handleModalClose: () => void;
};

export const ErrorModal: React.FC<ErrorModalProps> = ({
  title,
  errorMessage,
  errorInfo,
  onCtaClick,
  ctaText,
  handleModalClose,
  icon,
}) => {
  const intl = useIntl();

  const finalErrorMessage = useMemo(() => {
    return (
      errorMessage ||
      intl.formatMessage({
        id: 'tf_widget_technicalError_title',
        defaultMessage: 'Something went wrong.',
      })
    );
  }, [errorMessage, intl]);

  const finalCtaText = useMemo(() => {
    return (
      ctaText ||
      intl.formatMessage({
        id: 'tf_widget_booking_cc_error_modal_button_try_again',
        defaultMessage: 'Try again',
      })
    );
  }, [ctaText, intl]);

  const finalErrorInfo = useMemo(() => {
    if (!errorInfo) return null;
    return (
      <S.ErrorModalText>
        {typeof errorInfo === 'string' ? (
          errorInfo
        ) : (
          <FormattedMessage
            id={errorInfo.id}
            defaultMessage={errorInfo.defaultMessage}
            values={{
              br: () => <br />,
            }}
          />
        )}
      </S.ErrorModalText>
    );
  }, [errorInfo]);

  const handleCtaClose = async () => {
    if (onCtaClick) {
      await onCtaClick();
    }
    handleModalClose();
  };

  return (
    <Modal title={title} hasCloseIcon={!onCtaClick} {...(!onCtaClick && { onClose: handleModalClose })}>
      <S.ErrorModalContent data-testid="error-modal-content">
        {icon && <S.ErrorModalIcon icon={icon} />}
        <S.ErrorModalTitle>{finalErrorMessage}</S.ErrorModalTitle>
        {finalErrorInfo}
        <S.ErrorModalButtonContainer>
          <Button variant="primary" mb="xs" width="100%" onClick={() => handleCtaClose()}>
            {finalCtaText}
          </Button>
        </S.ErrorModalButtonContainer>
      </S.ErrorModalContent>
    </Modal>
  );
};
