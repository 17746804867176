import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ModalBody, Wrapper, ModalHeader, StyledOverlay } from './Modal.style';
import { Heading } from '@/components/Heading/Heading';
import useViewport from '@/hooks/useViewport';

export type ModalProps = {
  title?: string;
  hasCloseIcon?: boolean;
  children: React.ReactNode;
  onClose?: () => void;
};

export const Modal = ({ title, hasCloseIcon = true, children, onClose }: ModalProps) => {
  const { isDesktop } = useViewport();
  useEffect(() => {
    if (document.body.style.overflow !== 'hidden') {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      if (document.body.style.overflow === 'hidden') {
        document.body.style.overflow = '';
      }
    };
  }, []);

  return ReactDOM.createPortal(
    <Wrapper>
      <StyledOverlay
        position="center"
        hasCloseIcon={hasCloseIcon}
        hasBackIcon={false}
        onClose={onClose}
        data-testid="modal-overlay"
      >
        {title && (
          <ModalHeader>
            <Heading variant={isDesktop ? 'title2' : 'title3'} m={0}>
              {title}
            </Heading>
          </ModalHeader>
        )}
        <ModalBody>{children}</ModalBody>
      </StyledOverlay>
    </Wrapper>,
    document.body,
  );
};
