import { ErrorModal } from '@/components/ErrorModal/ErrorModal';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { MessageDescriptor } from '@formatjs/intl';

export type ErrorModalConfig = {
  errorMessage?: string;
  errorInfo?: string | MessageDescriptor;
  title?: string;
  onCtaClick?: () => void | Promise<void>;
  ctaText?: string;
  icon?: React.FC;
};

type ErrorModalContextType = {
  errorConfig: ErrorModalConfig | null;
  showErrorModal: (config: ErrorModalConfig) => void;
  closeErrorModal: () => void;
};

const ErrorModalContext = createContext<ErrorModalContextType | undefined>(undefined);

export const ErrorModalContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [errorModalConfig, setErrorModalConfig] = useState<ErrorModalConfig | null>(null);

  const showErrorModal = (config: ErrorModalConfig) => {
    if (!errorModalConfig) {
      setErrorModalConfig(config);
    }
  };

  const closeErrorModal = () => {
    setErrorModalConfig(null);
  };

  return (
    <ErrorModalContext.Provider value={{ errorConfig: errorModalConfig, showErrorModal, closeErrorModal }}>
      {children}
      {errorModalConfig && (
        <ErrorModal
          errorMessage={errorModalConfig.errorMessage}
          errorInfo={errorModalConfig.errorInfo}
          title={errorModalConfig.title}
          onCtaClick={errorModalConfig.onCtaClick}
          ctaText={errorModalConfig.ctaText}
          icon={errorModalConfig.icon}
          handleModalClose={closeErrorModal}
        />
      )}
    </ErrorModalContext.Provider>
  );
};

export const useErrorModal = () => {
  const context = useContext(ErrorModalContext);
  if (!context) {
    throw new Error('useErrorModal must be used within an ErrorModalProvider');
  }
  return context;
};
